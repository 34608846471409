export const cardData = [
  {
    id: 1,
    title: '北京左医科技有限公司',
    tagList: ['技术标签', '领域标签', '行业标签', '行为标签'],
    imgUrl: '',
    priority: 1, //1代表高 2 代表中 3 代表低
    grade: 'A',
    people: '张超',
    phone: '12345678901',
    location: '中国-北京市-西城区',
    contacts: '2',
    records: '2',
    updateTime: '2025-03-24 06:13:00',
    activeTxt:'协议签署'
  }
]
