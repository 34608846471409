<template>
  <div>
    <el-card>
      <el-form v-model="queryInfo" label-width="120px">
        <el-form-item label="所在地区：">
          <EarthAreaSelect
            :country="'中国'"
            :province="queryInfo.province"
            :city="queryInfo.city"
            @searchCountry="searchCountry"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
          />
        </el-form-item>
        <el-form-item label="赋能行业：">
          <dic-tag-button
            ref="tagButton"
            :code.sync="queryInfo.industry"
            query-name="techIdName"
            name="所在行业"
            tag="行业"
            key="所在行业"
            @onChange="onChange"
          />
        </el-form-item>
        <el-form-item label="数智产业链：">
          <el-radio-group v-model="queryInfo.industryChain" @change="changeSearchangeSearch">
            <el-radio-button label="" :value="''">全部</el-radio-button>
            <el-radio-button
              :label="item.value"
              v-for="(item, index) in dict.type['037']"
              :key="index"
            >{{ item.label }}
            </el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="拓展阶段：">
          <el-radio-group v-model="queryInfo.developmentStage" @change="changeSearchangeSearch">
            <el-radio-button label="" :value="''">全部</el-radio-button>
            <el-radio-button
              :label="item.value"
              v-for="(item, index) in dict.type['038']"
              :key="index"
            >{{ item.label }}
            </el-radio-button
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="top">
        <div class="top_btn">
          <el-button type="primary">数据订阅</el-button>
          <el-button>订阅历史</el-button>
        </div>
        <div class="input-with">
          <div>
            <el-input
              placeholder="请输入企业名称"
              v-model="queryInfo.keyword"
              class="input-with-select"
            >
              <el-button class="seach" slot="append" @click="changeSearchangeSearch"
              >搜索
              </el-button
              >
            </el-input>
          </div>
        </div>
      </div>
    </el-card>
    <div class="tick_text">
      <div class="left_text">当前条件下共查出<span>4</span>家待拓展服务商</div>
      <div class="sort">
        <span>按最近操作时间</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="sortCount === 1 ? 'top_active' : ''"
            @click="handleSort"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="sortCount === 2 ? 'bottom_active' : ''"
            @click="handleSort"
          ></div>
        </div>
      </div>
      <div class="sort">
        <span>按拓展优先级</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="sortCount === 1 ? 'top_active' : ''"
            @click="handleSort"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="sortCount === 2 ? 'bottom_active' : ''"
            @click="handleSort"
          ></div>
        </div>
      </div>
      <div class="sort">
        <span>按服务商等级</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="sortCount === 1 ? 'top_active' : ''"
            @click="handleSort"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="sortCount === 2 ? 'bottom_active' : ''"
            @click="handleSort"
          ></div>
        </div>
      </div>
    </div>
    <card-list :cardData="cardData"/>
  </div>
</template>

<script>
import DicTagButton from '@/components/DicTagButton.vue'
import EarthAreaSelect from '@/components/EarthAreaSelect'
import CardList from '@/views/service/serviceExpansion/components/cardList.vue'
import {cardData} from '@/views/service/serviceExpansion/components/cardData'

export default {
  name: 'serviceExpansion',
  components: { CardList, DicTagButton, EarthAreaSelect },
  dicts: ['037', '038'],
  data() {
    return {
      sortCount: '1',
      cardData,
      queryInfo: {
        keyword: '', //企业名称
        developmentStage: '',  // 拓展阶段
        industryChain: '', // 数智产业链
        pageSize: 10,
        pageNum: 1,
        industry: '', //所在行业
        order: 1, // 0正序 1倒叙
        country: '',
        province: '',
        city: ''
      }
    }
  },
  created() {
  },
  methods: {
    // 点击筛选条件
    handleSort() {
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    },
    // 查询一览
    search() {
    },
    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      this.queryInfo.pageNum = 1
      this.queryInfo.labels = checkboxVal
      this.search()
    },
    searchCountry(val) {
      this.queryInfo.country = val
      this.search()
    },
    searchProvince(val) {
      this.queryInfo.province = val
      this.search()
    },
    searchCity(val) {
      if (val != '北京' && val != '天津' && val != '上海' && val != '重庆') {
        this.queryInfo.city = val
        this.search()
      }
    }
  }
}
</script>


<style scoped lang="less">
::v-deep .el-radio-button__inner {
  border: none;
  margin-right: 12px;
  border-radius: 4px;
}

::v-deep .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 4px;
}

::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: none;
  border-radius: 4px;
}

.tick_text{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 10px 0;

  .left_text{
    display: flex;
    align-items: center;
    color: #393939;
    font-size: 16px;

    span{
      color: #448AFF;
    }
  }

  .sort {
    display: flex;
    align-items: center;
    margin-left: 20px;
    span {
      cursor: pointer;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #595959;
      margin-right: 5px;
    }
    .sort_icon {
      cursor: pointer;
      display: flex;
      flex-flow: column;
    }
  }
}



.top {
  width: 100%;
  box-sizing: border-box;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px dashed #efefef;
}


.input-with {
  .input-with-select {
    width: 400px;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

</style>
