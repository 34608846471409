<template>
  <div class="earth-area-select">
    <el-select
      v-model="select.country"
      placeholder="请选择国家"
      @change="changeCountry"
      class="middle-select"
    >
      <el-option value label="不限"></el-option>

      <el-option
        v-for="item in list.countrys"
        :key="item.id"
        :label="item.name"
        :value="item.name"
      ></el-option>
    </el-select>

    <el-select
      v-model="select.province"
      placeholder="请选择省份"
      @change="changeProvince"
      class="middle-select"
      :disabled="!(list.provinces && list.provinces.length)"
    >
      <el-option value label="不限"></el-option>

      <el-option
        v-for="item in list.provinces"
        :key="item.id"
        :label="item.name"
        :value="item.name"
      ></el-option>
    </el-select>

    <el-select
      v-model="select.city"
      placeholder="请选择市"
      @change="changeCity"
      class="middle-select"
      :disabled="!(list.citys && list.citys.length)"
    >
      <el-option value label="不限"></el-option>

      <el-option
        v-for="item in list.citys"
        :key="item.id"
        :label="item.name"
        :value="item.name"
      ></el-option>
    </el-select>

    <el-select
      v-model="select.district"
      placeholder="请选择区"
      @change="changeDistrict"
      class="middle-select"
      :disabled="!(list.district && list.district.length)"
    >
      <el-option value label="不限"></el-option>

      <el-option
        v-for="item in list.district"
        :key="item.id"
        :label="item.name"
        :value="item.name"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { getCityAll, getForeignArea } from '@/api/common'

export default {
  name: 'EarthAreaSelect',
  props: {
    country: {
      type: String
    },
    province: {
      type: String
    },
    city: {
      type: String
    }
  },
  data() {
    return {
      list: {
        countrys: [],
        provinces: [],
        citys: [],
        district: []
      },
      select: {
        province: null,
        city: null,
        district: null
      }
    }
  },
  created() {
    this.getForeignAreaAPI()
  },
  methods: {
    async getForeignAreaAPI() {
      let countrysData = sessionStorage.getItem('countrys')
      try {
        if (!countrysData) {
          const { data: res } = await getForeignArea()
          this.list.countrys = [
            {
              id: 1,
              name: '中国'
            },
            ...res.data
          ]
          sessionStorage.setItem(
            'countrys',
            JSON.stringify(this.list.countrys)
          )
        } else {
          this.list.countrys = JSON.parse(countrysData)
        }
        this.search()
      } catch (err) {
        console.error({
          component: 'EarthAreaSelect',
          error: err
        })
      }
    },
    async search() {
      try {
        let cityData = sessionStorage.getItem('area')

        if (!cityData) {
          const { data: res } = await getCityAll()
          sessionStorage.setItem('area', JSON.stringify(res.data))
          this.list.countrys[0].cityResults = res.data
        } else {
          this.list.countrys[0].cityResults = JSON.parse(cityData)
        }

        if (this.country) {
          this.select.country = this.country
          this.changeCountry(this.country)
        }

        if (this.province) {
          this.select.province = this.province
          this.changeProvince(this.province)
        }

        if (this.city) {
          this.select.city = this.city
          this.changeCity(this.city)
        }
      } catch (err) {
        console.error({
          component: 'EarthAreaSelect',
          error: err
        })
      }
    },
    changeCountry(val) {
      this.list.provinces = []
      this.list.citys = []
      this.list.district = []

      this.list.countrys.forEach(item => {
        if (item.name === val) {
          this.list.provinces = item.cityResults
        }
      })

      this.select.province = null
      this.select.city = null
      this.$emit('searchArae', { ...this.select })
    },
    changeProvince(val) {
      this.list.citys = []
      this.list.district = []

      if (Array.isArray(this.list.provinces)) {
        this.list.provinces.forEach(item => {
          if (item.name === val) {
            this.list.citys = item.cityResults
          }
        })
      }

      this.select.city = ''

      this.$emit('searchArae', { ...this.select })
    },
    changeCity(val) {
      this.list.district = []

      this.list.citys.forEach(item => {
        if (item.name === val) {
          this.list.district = item.cityResults
        }
      })

      this.select.district = ''

      this.$emit('searchArae', { ...this.select })
    },
    changeDistrict(val) {
      this.$emit('searchArae', { ...this.select })
    },
    clear() {
      this.select = {
        country: '',
        province: '',
        city: ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.earth-area-select {
  .el-select {
    margin-right: 10px;
  }
}
</style>
