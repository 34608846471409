<template>
  <div class="card_list">
    <div class="card_item" v-for="(item,index) in cardData" :key="index">
      <div class="card_item_left">
        <div class="card_item_left_img">
          <img src="../../../../assets/image/Group 3237.png" alt="">
          <span>等级 <em>A</em></span>
        </div>
        <div class="xq">
          <div class="xq_top">
            <h4>{{ item.title }}</h4>
            <span class="span1">中优先级</span>
          </div>
          <div class="list_tag">
            <span>技术标签</span>
            <span>领域标签</span>
            <span>行业标签</span>
            <span>行为标签</span>
          </div>
          <div class="bottom">
            <span class="name">张毅</span>
            <span class="marginlr4">|</span>
            <span class="phone">12367898738</span>
            <span class="marginlr4">|</span>
            <span>中国-北京市-西城区</span>
          </div>
        </div>
      </div>
      <div class="card_item_center">
        <el-steps :active="getActiveNum(item.activeTxt)" align-center>
          <el-step title="电话沟通" description="2025-01-05"></el-step>
          <el-step title="微信对接" description="2025-01-10"></el-step>
          <el-step title="建群管理" description="2025-01-15"></el-step>
          <el-step title="资料上传" description="2025-01-20"></el-step>
          <el-step title="协议签署" description="2025-01-25"></el-step>
          <el-step title="运营启动" description="2025-02-01"></el-step>
        </el-steps>
      </div>
      <div class="card_item_right">
        <div class="right_top">
          <div class="allocation">
            <img src="../../../../assets/image/Group 3803.png" alt="">
            <span class="no">未分配</span>
          </div>
          <div class="btn">
            <el-button plain class="w110">联系人(2)</el-button>
            <el-button plain class="w110">跟进记录(2)</el-button>
            <el-button plain class="w110">转化合作</el-button>
            <el-button type="text">详情</el-button>
            <el-button class="close" type="text">冻结</el-button>
          </div>
        </div>
        <div class="right_bottom">
          <span>最近操作时间：2025-03-24 06:13:00</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'cardList',
  props: {
    cardData: {
      type: Array
    }
  },
  computed: {
    getActiveNum() {
      const activeMap = {
        '电话沟通': 0,
        '微信对接': 1,
        '建群管理': 2,
        '资料上传': 3,
        '协议签署': 4,
        '运营启动': 5
      }

      return function(row) {
        // 确保输入是字符串类型，并提供默认值
        if (typeof row !== 'string') {
          console.warn('输入类型错误，期望字符串类型')
          return 0
        }
        // 从映射中获取值，若不存在则返回默认值 0
        return activeMap[row] || 0
      }
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
::v-deep .el-step__description.is-finish {
  color: #333333;
}

.card_list {
  width: 100%;
  overflow: hidden;

  .card_item {
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 4px;

    .card_item_left {
      width: 26%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .card_item_left_img {
        width: 88px;
        height: 88px;
        cursor: pointer;
        position: relative;

        img {
          width: 88px;
          height: 88px;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 4px;
        }

        span {
          width: 68px;
          height: 28px;
          display: inline;
          text-align: center;
          line-height: 28px;
          position: absolute;
          background-color: #448AFF;
          color: #ffffff;
          font-size: 12px;
          font-weight: normal;
          border-radius: 8px 16px 16px 0;
          left: -10px;
          top: -14px;

          em {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
          }
        }
      }

      .xq {
        flex: 1;
        margin-left: 12px;
        display: flex;
        flex-direction: column;

        .xq_top {
          width: 100%;
          display: flex;
          align-items: center;

          h4 {
            margin: 0;
            color: #151515;
            cursor: pointer;
            font-size: 18px;
            font-weight: 550;
          }

          span {
            width: 74px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            border-radius: 14px;
            margin-left: 4px;
          }

          .span1 {
            background-color: #F53939;
          }

          .span2 {
            background-color: #FFCC00;
          }

          .span3 {
            background-color: #34C759;
          }
        }

        .list_tag {
          width: 100%;
          display: flex;
          align-items: center;
          margin: 10px 0;

          span {
            box-sizing: border-box;
            padding: 3px 6px;
            display: inline-block;
            border: .5px solid #559EFF;
            border-radius: 2px;
            margin-right: 10px;
            color: #448AFF;
            font-size: 14px;
            cursor: pointer;
            font-weight: 400;
          }
        }

        .bottom {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .marginlr4 {
            margin: 0 4px;
            color: #EFEFEF;
            font-size: 14px;
          }

          span {
            color: #333333;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }

    .card_item_center {
      flex: 1;
      margin: auto 20px;
    }

    .card_item_right {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      .right_top {
        width: 100%;
        display: flex;
        align-items: center;

        .allocation {
          display: flex;
          align-items: center;
          margin-right: 14px;
          min-width: 70px;

          img {
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }

          span {
            color: #4E93FB;
            text-decoration: underline;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
          }

          .no {
            color: #FD702B;
          }
        }

        .btn {
          flex: 1;
          display: flex;
          align-items: center;

          .w110 {
            width: 110px;
          }

          .close {
            color: #FD5469;
          }
        }
      }

      .right_bottom {
        color: #999999;
        font-size: 12px;
        font-weight: 400;
        margin-top: 10px;
      }
    }
  }
}
</style>
